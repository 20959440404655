import styled from 'styled-components';

import { SIZES } from '@style/sizes';
import DefaultTheme from '@style/themes/main';

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: ${DefaultTheme.palette.quinternary};
`;

export const WrapperHeader = styled.div`
  width: 100%;
  height: 10%;
`;

export const WrapperBody = styled.div`
  @media (max-width: ${SIZES.MOBILE_575}px) {
    flex-direction: column;
    height: 100%;
  }
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const WrapperLogo = styled.div`
  width: 175px;
  height: 30px;
  padding: 20px;
  box-sizing: border-box;
`;

export const IconLink = styled.a``;

export const WrapperAnimation = styled.div`
  @media (max-width: ${SIZES.TABLET_768}px) {
    height: 250px;
  }
  @media (max-width: ${SIZES.MOBILE_575}px) {
    height: 140px;
  }
  width: 300px;
  height: 270px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.p`
  @media (max-width: ${SIZES.MOBILE_575}px) {
    font-size: 140px;
    line-height: 140px;
  }
  font-size: 146px;
  line-height: 146px;
  padding-bottom: 30px;
  color: ${DefaultTheme.palette.secondary};
  font-family: ${DefaultTheme.fontFamilies.europa.bold};
`;

export const Subtitle = styled.p`
  @media (max-width: ${SIZES.MOBILE_575}px) {
    font-size: 22px;
  }
  font-size: 24px;
  line-height: 24px;
  padding-bottom: 10px;
  color: ${DefaultTheme.palette.secondary};
  font-family: ${DefaultTheme.fontFamilies.europa.bold};
`;
export const Message = styled.div`
  @media (max-width: ${SIZES.MOBILE_575}px) {
    font-size: 18px;
  }
  max-width: 500px;
  font-size: 20px;
  line-height: 30px;
  color: ${DefaultTheme.palette.common.grey};
  font-family: ${DefaultTheme.fontFamilies.europa.regularItalic};
`;

export const WrapperText = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 15px;
`;
