import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { INotFoundData } from 'types/404';

import LottiePlayer from '@components/LottiePlayer';

import { PATHS } from '@global/urls';
import { getImageData, ParseImages } from '@helpers/imageHelper';

import AnimationPath from '../components/404/animation/Motive-not-found-page.json';
import {
  IconLink,
  Message,
  Subtitle,
  Title,
  Wrapper,
  WrapperAnimation,
  WrapperBody,
  WrapperHeader,
  WrapperLogo,
  WrapperText,
} from '../components/404/styles';

function NotFoundPage(props: INotFoundData) {
  const { data } = props;
  const { t } = useTranslation();
  const logoImage = ParseImages(data);

  return (
    <Wrapper>
      <WrapperHeader>
        <WrapperLogo>
          <IconLink href={PATHS.INDEX}>
            <GatsbyImage alt={'motive-logo'} image={getImageData(logoImage, 'logo')} />
          </IconLink>
        </WrapperLogo>
      </WrapperHeader>
      <WrapperBody>
        <WrapperAnimation>
          <LottiePlayer animationJsonPath={AnimationPath} loop autoplay />
        </WrapperAnimation>
        <WrapperText>
          <Title>{t('404:TITLE')}</Title>
          <Subtitle>{t('404:SUBTITLE')}</Subtitle>
          <Message>{t('404:TEXT')}</Message>
        </WrapperText>
      </WrapperBody>
    </Wrapper>
  );
}

export const pageQuery = graphql`
  query ($language: String!) {
    allFile(filter: { relativeDirectory: { in: ["assets"] } }) {
      edges {
        node {
          name
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    locales: allLocale(filter: { ns: { in: ["404"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default NotFoundPage;
